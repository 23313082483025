import React from "react"
import { graphql } from "gatsby"

import ContentLayout from "../components/ContentLayout"

import Icon from "../svg/menu/tata.svg"
import CategoryList from "../components/CategoryList"

const TataPage = ({
  data: {
    practices: { nodes: practices },
    cover: { childImageSharp: cover },
  },
}) => (
  <ContentLayout icon={Icon} title="Tata" cover={cover.fluid}>
    <CategoryList title="Gyakorlati helyek" items={practices} />
  </ContentLayout>
)

export default TataPage

export const query = graphql`
  query tata {
    practices: allMdx(
      filter: {
        frontmatter: { category: { eq: "practice" } }
        slug: { regex: "/^(tata)/.*$/" }
      }
    ) {
      nodes {
        id
        path: slug
        frontmatter {
          title
        }
      }
    }
    cover: file(relativePath: { eq: "covers/tata.png" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
